import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Paragraph from "../shared/paragraphs";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

// just to trigger the rebuild for the blog post

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  width: 80%;
  margin: auto;

  height: 100%;
  gap: 30px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 499px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const RulesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 792px;
  gap: 40px;
  margin-bottom: 90px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TermsTitles = styled.div`
  color: #111111;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  font-style: normal;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;
  margin: 0px;
  padding: 0px;
`;

function Hero() {
  const intl = useIntl();

  const html = `${intl.formatMessage({
    id: "EULA",
  })}`;
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          {html != "exist" && (
            <div
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></div>
          )}
          {html == "exist" && (
            <div class="wrapper">
              <header>
                <h1>Acuerdo de licencia para el usuario final</h1>
                <em>Última actualización: 28 de mayo de 2018</em>
              </header>
              <br></br>
              <div class="content" style="font-size: 1rem;">
                <p>
                  Este acuerdo de licencia para el usuario final (en lo
                  sucesivo, el <b>“EULA”</b> por sus siglas en inglés)
                  constituye un acuerdo legal y vinculante entre PDF Pro
                  Software Inc., con domicilio social en 1383 W 8th Ave,
                  Vancouver, BC V6H 3V9, Canada, registro mercantil no.
                  700468291BC0001, incluidos sus empresas subsidiarias, sus
                  socios y contratistas que actúen en su nombre (en lo sucesivo,
                  denominados colectivamente <b>“Nosotros”</b> o{" "}
                  <b>“Nuestro”</b>) y los usuarios finales y sus herederos,
                  agentes, sucesores y cesionarios (en lo sucesivo, denominados
                  individual y colectivamente <b>“Usted”</b> o <b>“Su”</b>) que
                  utilicen cualquiera de nuestros productos de software “PDF
                  Pro”, “PDF Creator”, “PDF Reader”, “PDF Editor” y “PDF
                  Writer”, y cualquier documentación relacionada (incluyendo,
                  sin restricción, documentación estándar del producto,
                  documentación técnica y del usuario, documentos adicionales
                  que describan las funcionalidades estándar de los mismos,
                  etc.), aplicación, componente y cualquier modificación o
                  actualización de los mismos (en lo sucesivo, todos y cada uno
                  de los elementos mencionados se denominarán individual y
                  colectivamente el <b>“Software”</b>).
                </p>
                <p>
                  <b>
                    AVISO IMPORTANTE: el uso continuado de este Software
                    constituye Su aceptación expresa y el compromiso de quedar
                    obligado por este EULA, vigente cuando corresponda. Por lo
                    tanto, Usted comprende y reconoce que cuando descargue,
                    acceda, instale o utilice el Software, acepta quedar sujeto
                    a los términos de este EULA. Si no acepta dichos términos en
                    tu totalidad, no debe descargar, acceder, instalar o
                    utilizar el Software. Si acepta este EULA en nombre de una
                    entidad, Usted declara y garantiza que tiene la autoridad
                    para vincular dicha entidad a este EULA. En tal caso,
                    “Usted” y “Su” se referirán y se aplicarán a esa entidad o
                    compañía.
                  </b>
                </p>
                <h2>SECCIÓN 1. - LICENCIA: DERECHO DE USO DEL SOFTWARE</h2>
                <p>
                  <b>1.1. Concesión de la licencia.</b> Sujeta al pago puntual
                  de todas las tarifas aplicables, se le concede una licencia
                  limitada, personal, mundial, no asignable, sin posibilidad de
                  licenciar a más personas, intransferible y no exclusiva para
                  usar el Software, tal como se especifica en el pedido
                  relacionado de compra, que se considerará incorporado aquí (en
                  lo sucesivo, el “Pedido”). A menos que se especifique lo
                  contrario en el Pedido, se le permite usar el Software solo en
                  un (1) dispositivo informático.
                </p>
                <p>
                  <b>1.2. Alcance de la licencia.</b> Usted acepta que utilizará
                  el Software solo para uso personal. No podrá, sin nuestro
                  permiso expreso por escrito, vender, arrendar, proporcionar o
                  poner a disposición de un tercero (directamente o a través de
                  una interfaz web) dicho Software o utilizarlo en beneficio de
                  un tercero.
                </p>
                <p>
                  <b>1.3. Idioma.</b> El Software se proporciona en los
                  siguientes idiomas: inglés, francés, alemán y español. El
                  servicio de asistencia solo se proporciona en inglés, pero
                  podemos enviarle mensajes traducidos automáticamente si nos
                  hace saber que no habla inglés.
                </p>
                <p>
                  <b>1.4. Reserva de derechos.</b> Nos reservamos expresamente
                  todos los derechos no otorgados expresamente a Usted en este
                  EULA.
                </p>
                <h2>SECCIÓN 2.- REQUISITOS MÍNIMOS Y ACTIVACIÓN</h2>
                <p>
                  <b>2.1. Requisitos mínimos.</b> Para instalar completamente el
                  Software deberá tener un PC con un sistema operativo Windows 7
                  como mínimo y al menos 150 MB libres de RAM y 300 Mb de
                  espacio libre en el disco duro. Usted acepta que es el único
                  responsable de estos requisitos.
                </p>
                <p>
                  <b>2.2. Activación de licencia de Software.</b> Para ejecutar
                  el Software deberá activar una clave de licencia después de
                  proporcionarnos información precisa y completa según lo
                  indique el formulario de Pedido. A tal efecto, Usted reconoce
                  y acepta expresamente que: (i) como parte de Nuestras medidas
                  para verificar el uso de las claves de activación, cuando una
                  conexión a internet esté abierta, el Software puede probar la
                  validez de la clave utilizada para la misma; (ii) activar o
                  ejecutar el Software más allá de un cierto periodo de tiempo
                  puede requerir la introducción de una clave de activación de
                  licencia que previamente le haya sido entregada a Usted.
                </p>
                <h2>
                  SECCIÓN 3.- PRUEBA, SERVICIO DE ASISTENCIA Y ACTUALIZACIONES
                </h2>
                <p>
                  <b>3.1. Prueba gratuita.</b> Si solicita una versión de
                  prueba, pondremos a Su disposición el Software requerido a
                  título de prueba, de forma gratuita, antes de: (i) el periodo
                  de prueba de 15 (quince) días desde la instalación del
                  Software, o (ii) la fecha de inicio del Software pedido y
                  comprado por Usted antes del final de dicho periodo de prueba.
                  Usted reconoce y acepta que, cuando esté en modo de prueba, el
                  Software coloque una marca de agua en la parte inferior de
                  cualquier archivo creado o editado recientemente.
                </p>
                <p>
                  <b>3.2. Servicio de asistencia.</b> Utilizaremos los esfuerzos
                  comercialmente razonables para proporcionarle asistencia por
                  correo electrónico dentro de los 2 (dos) días hábiles a partir
                  de su solicitud del servicio.
                </p>
                <p>
                  <b>3.3. Actualizaciones.</b> Nosotros, a Nuestro exclusivo
                  criterio, nos reservamos el derecho de añadir herramientas
                  adicionales, utilidades, aplicaciones de terceros,
                  características o funciones, corregir errores, ofrecer
                  actualizaciones y mejoras, al Software para aumentar las
                  funciones y el rendimiento de las mismas (en lo sucesivo,
                  denominadas colectivamente “Actualizaciones”). En caso de que
                  proporcionemos Actualizaciones, este EULA se aplicará
                  automáticamente, a menos que proporcionemos otros términos
                  junto a dichas Actualizaciones. Cuando estén disponibles las
                  Actualizaciones, se le proporcionarán gratuitamente hasta el
                  final del primer año de vigencia de este EULA. A partir de ese
                  momento, las Actualizaciones estarán sujetas al pago de las
                  tarifas aplicables (según lo dispuesto en la Sección 5.1.).
                </p>
                <h2>SECCIÓN 4.- SUS RESPONSABILIDADES</h2>
                <p>
                  <b>4.1. Responsabilidades generales.</b> Usted es totalmente
                  responsable del uso del Software y, para tal fin, no deberá
                  utilizar (o inducir a otros a utilizar) el Software para
                  ninguna actividad que viole cualquier ley, estatuto, norma,
                  ordenanza o regulación de carácter local, estatal, nacional e
                  internacional aplicable (en lo sucesivo, singular y
                  colectivamente, la “Ley”).
                </p>
                <p>
                  <b>4.2. Confidencialidad de la clave de licencia.</b> Usted es
                  totalmente responsable de salvaguardar y mantener la seguridad
                  y confidencialidad de las claves de licencia de Su Software.
                  Usted reconoce y acepta que Nosotros asumiremos que cualquier
                  persona que use la (s) clave (s) de licencia de Su Software,
                  ya sea Usted o alguien autorizado para actuar en Su nombre.
                  Usted se compromete a no compartir la (s) clave (s) de
                  licencia de Su Software a ningún tercero y a hacer todos los
                  esfuerzos razonables para evitar que puedan acceder a Su
                  Software terceros no autorizados. Si tiene motivos para creer
                  que han robado las claves de licencia de Su Software, acepta
                  notificarnos el hecho de inmediato.
                </p>
                <p>
                  <b>4.3. Personas autorizadas.</b> Puede autorizar a Sus
                  empleados a acceder al Software, siempre que dicho acceso sea
                  para Su beneficio particular y en total conformidad con este
                  EULA. A tal efecto, Usted será responsable del cumplimiento de
                  este EULA por parte de Sus empleados, así como de todas las
                  actividades que ocurran como resultado de su acceso al
                  Software.
                </p>
                <p>
                  <b>4.4. Indemnización.</b> A cualquier reclamación de un
                  tercero que surja de una violación de Sus obligaciones
                  conforme a esta Sección, se aplicará la Sección 10
                  (Indemnización) además de cualquier otro derecho o recurso que
                  nos sea otorgado en virtud de este EULA y / o la ley
                  aplicable.
                </p>
                <h2>SECCIÓN 5.- TARIFAS DE LICENCIA Y REEMBOLSOS</h2>
                <p>
                  <b>5.1. Tarifas de licencia.</b> Las tarifas correspondientes
                  a los derechos otorgados en este documento, así como los
                  términos de pago de los mismos, se especifican en el Pedido
                  aplicable. El servicio de Actualizaciones mencionado en la
                  Sección 4.1. es gratuito durante el primer año de plazo de
                  este EULA. Tras la finalización de este periodo, las
                  Actualizaciones del Software están sujetas a adquisición al
                  precio vigente en ese momento.
                </p>
                <p>
                  <b>5.2. Reembolso.</b> Le ofrecemos una garantía de 30 días
                  para devolver el producto y reembolsarle su dinero en caso de
                  no estar satisfecho con el uso del Software. Esta garantía no
                  se aplicara si: (i) el Software no se ha utilizado según este
                  EULA; (ii) Su insatisfacción ha sido causada por Su no
                  aplicación de las Actualizaciones o cualquier otra acción o
                  instrucción recomendada por Nosotros; (iii) Su insatisfacción
                  ha sido causada por Su acto u omisión; o (iv) Su
                  insatisfacción resulta de cualquier causa fuera de nuestro
                  control razonable. No reembolsamos por falta de uso o por la
                  interrupción, por cualquier motivo, del servicio o la
                  funcionalidad de ningún Software.
                </p>
                <h2>SECCIÓN 6.- PLAZO Y FINALIZACIÓN</h2>
                <p>
                  <b>6.1. Plazo y finalización.</b> Este EULA entra en vigor el
                  primer día en que el Software se puso a Su disposición (a
                  través de la activación de la clave de licencia, la entrega o
                  cualquier punto reseñado en el segundo párrafo de este EULA
                  titulado “AVISO IMPORTANTE”), y permanecerá vigente durante un
                  periodo indefinido, hasta que finalice o: (i) Usted, en
                  cualquier momento, desinstale el Software; o (ii) Nosotros lo
                  decidamos, en cualquier momento, debido a un incumplimiento
                  por Su parte de cualquiera de los términos aquí acordados, sin
                  previo aviso ni responsabilidad de ningún tipo al respecto.
                  Tras la finalización de este EULA, las claves de licencia de
                  Software aplicables se desactivarán y Usted ya no tendrá
                  derechos de acceso y uso del Software.
                </p>
                <p>
                  <b>6.2. Obligaciones tras la finalización.</b> Las
                  disposiciones que subsisten tras la finalización o vencimiento
                  de este EULA son aquellas relacionadas con la limitación de
                  responsabilidad, indemnización, pago y demás disposiciones que
                  por su naturaleza estén destinadas a subsistir.
                </p>
                <h2>SECCIÓN 7.- DERECHOS DE PROPIEDAD INTELECTUAL</h2>
                <p>
                  <b>7.1. Nuestra propiedad intelectual.</b> Nosotros, como
                  licenciadores, poseemos y conservamos todos los títulos e
                  intereses sobre el Software y sobre cualquier derecho de
                  propiedad intelectual asociado con el mismo. Por lo tanto,
                  nada en este EULA se podrá interpretar como una transferencia,
                  en todo o en parte, de cualquier derecho sobre el Software
                  para Usted o cualquier otro tercero. A los efectos del
                  presente EULA, “<b>Derechos de Propiedad Intelectual</b>” son
                  todos los derechos de propiedad intelectual y otros derechos
                  de propiedad similares en cualquier jurisdicción, ya sean de
                  propiedad o de posesión para uso bajo licencia, registrados o
                  no, incluidos derechos en y para: (i) marcas registradas,
                  logotipos, nombres comerciales y la buena fe asociada con lo
                  anterior; (ii) patentes y solicitudes de patente y todas y
                  cada una de las divisiones, continuaciones, reediciones,
                  revisiones y extensiones de las mismas; (iii) estilo de
                  escritura y otras formas de autoría; (iv) secretos
                  comerciales, “know-how”, información no pública, información
                  de propiedad e información confidencial, y derechos para
                  limitar el uso y divulgación de los mismos por parte de
                  cualquier persona; (v) software, incluidos archivos de datos,
                  código fuente, código objeto, interfaces de programación de
                  aplicaciones, bases de datos y otras especificaciones y
                  documentación relacionadas con el software; (vi) nombres de
                  dominio, localizadores uniformes de recursos y direcciones de
                  internet; (vii) información técnica, especificaciones,
                  dibujos, registros, documentación, ideas, conocimiento,
                  divulgaciones de invenciones u otros datos; (viii)
                  reclamaciones, causas de acción y defensas relacionadas con la
                  ejecución de cualquiera de las anteriores.
                </p>
                <p>
                  <b>7.2. Atribución de licencia a terceros.</b> El “QT para el
                  desarrollo de la aplicación” lo licenciamos y distribuimos
                  Nosotros bajo la licencia de código abierto LGPLv3 (disponible
                  en{" "}
                  <a
                    href="https://www.gnu.org/licenses/lgpl-3.0.en.html"
                    target="_blank"
                  >
                    https://www.gnu.org/licenses/lgpl-3.0.en.html
                  </a>
                  ). Se puede encontrar más información sobre el proyecto QT en{" "}
                  <a
                    href="http://info.qt.io/download-qt-for-application-development"
                    target="_blank"
                  >
                    http://info.qt.io/download-qt-for-application-development
                  </a>
                  . Puede ponerse en contacto con nuestro servicio de asistencia
                  para obtener instrucciones sobre cómo volver a vincular la
                  biblioteca QT o para recibir la copia del código fuente QT.
                  Además, Nuestro Software también concede: (i) una licencia
                  comercial con distribución libre de derechos de autor de la
                  biblioteca DynaPDF, que puede comprarse en{" "}
                  <a href="https://www.dynaforms.com" target="_blank">
                    https://www.dynaforms.com
                  </a>
                  ; (ii) una licencia de desarrollador con distribución gratuita
                  para la biblioteca Universal Document Converter, que se puede
                  adquirir en{" "}
                  <a href="https://www.print-driver.com/" target="_blank">
                    https://www.print-driver.com/
                  </a>
                  .
                </p>
                <p>
                  <b>
                    7.3. Sus obligaciones para la preservación de Nuestros
                    Derechos de Propiedad Intelectual.
                  </b>{" "}
                  No debe causar ni permitir a otros: (i) eliminar o modificar
                  cualquier código de identificación, marca o aviso de Software,
                  ya sea Nuestro o de Nuestros licenciadores; (ii) copiar,
                  modificar, adaptar, realizar trabajos derivados de
                  desensamblar, descompilar, aplicar ingeniería inversa,
                  reproducir, distribuir, volver a publicar cualquier Software o
                  parte del mismo; (iii) realizar o divulgar pruebas
                  comparativas o de rendimiento del Software o cualquier
                  infraestructura asociada (como, por ejemplo, detección de
                  redes, identificación de puertos y servicios, escaneo de
                  vulnerabilidades, descifrado de contraseñas, pruebas de acceso
                  remoto, pruebas de entrada, etc.); (iv) licenciar, vender,
                  alquilar, arrendar, transferir, asignar, distribuir, alojar,
                  subcontratar o explotar comercialmente o poner el Software a
                  disposición de terceros.
                </p>
                <p>
                  <b>7.4. Sus comentarios y sugerencias.</b> Podemos utilizar
                  libremente Sus comentarios, sugerencias o ideas de cualquier
                  manera, incluso en modificaciones futuras del Software,
                  actualizaciones, otros productos o servicios, publicidad o
                  materiales de marketing. A tal fin, Usted nos otorga una
                  licencia perpetua, global, totalmente transferible, con
                  posibilidad de ser licenciada a otros, irrevocable,
                  completamente pagada y libre de derechos de autor para
                  utilizar los comentarios que nos ofrezca de cualquier manera.
                  No obstante lo anterior, no venderemos, publicaremos ni
                  compartiremos Sus comentarios de forma que se le pueda
                  identificar sin Su permiso explícito.
                </p>
                <h2>SECCIÓN 8.- PRIVACIDAD Y PROTECCIÓN DE DATOS</h2>
                <p>
                  <b>8.1. Política de Privacidad.</b> Usted declara haber leído
                  cuidadosamente Nuestra Política de Privacidad (disponible en{" "}
                  <a href="https://www.pdfcreator.es/privacidad">
                    www.pdfcreator.es/privacidad
                  </a>
                  ) y haber comprendido claramente cómo recopilamos, usamos,
                  protegemos o manejamos de otro modo la información relacionada
                  con una persona identificada o identificable.
                </p>
                <p>
                  <b>8.2. Nuestro derecho a acceder a los datos.</b> En la
                  medida permitida por la Ley aplicable, nos reservamos
                  expresamente el derecho de acceder, leer, preservar y divulgar
                  cualquier información que consideremos razonablemente
                  necesaria para: (i) satisfacer cualquier Ley aplicable o
                  solicitud de la Autoridad; (ii) hacer cumplir este EULA
                  (incluida la investigación de posibles violaciones) o Nuestros
                  Derechos de Propiedad Intelectual; (iii) detectar, prevenir o
                  abordar problemas de fraude, antipiratería, seguridad o temas
                  técnicos (incluidos, entre otros, verificar un registro
                  válido, el número de veces que se ejecutó el Software, la
                  dirección IP del dispositivo, la versión del Software, etc.);
                  (iv) realizar estadísticas internas y análisis de forma
                  anónima, incluido el rastreo del uso del Software con el fin
                  de proporcionar Actualizaciones, funcionalidades y mejoras de
                  manera correcta, o por razones de seguridad.
                </p>
                <h2>SECCIÓN 9.- GARANTÍA Y RESPONSABILIDAD</h2>
                <p>
                  <b>9.1. RENUNCIA DE GARANTÍA.</b> EL SOFTWARE SE PROPORCIONA
                  “TAL CUAL” Y “CON TODOS SUS DEFECTOS”, SIN GARANTÍA DE NINGÚN
                  TIPO. EXCEPCIÓN HECHA PARA LA GARANTÍA DE LA SECCIÓN 5.2. Y EN
                  LA MEDIDA PERMITIDA POR LA LEY APLICABLE, NEGAMOS CUALQUIER
                  GARANTÍA EXPRESA O IMPLÍCITA, INCLUIDA, SIN LIMITACIÓN,
                  CUALQUIER GARANTÍA IMPLÍCITA DE COMERCIABILIDAD, CALIDAD
                  SATISFACTORIA, IDONEIDAD PARA UN PROPÓSITO PARTICULAR, NO
                  VULNERACIÓN O DERIVADO DEL CURSO DE NEGOCIACIÓN, USO O
                  COMERCIO. HASTA EL GRADO MÁXIMO PERMITIDO O AUTORIZADO POR LA
                  LEY Y SIN LA LIMITACIÓN DE LO ANTERIOR, NO GARANTIZAMOS QUE EL
                  SOFTWARE CUMPLA CON SUS REQUISITOS NI QUE EL SOFTWARE NO SE
                  INTERRUMPIRÁ, SERÁ PUNTUAL, SEGURO O NO CONTENDRÁ ERRORES.{" "}
                  <b>
                    LAS EXCLUSIONES Y EXENCIONES DE RESPONSABILIDAD ANTERIORES
                    SON UNA PARTE FUNDAMENTAL DE ESTE EULA.
                  </b>
                </p>
                <p>
                  <b>9.2. RENUNCIA DE RESPONSABILIDAD.</b> HASTA EL GRADO MÁXIMO
                  PERMITIDO POR LA LEY APLICABLE Y LA EXCEPCIÓN HECHA POR MUERTE
                  O LESIÓN PERSONAL CAUSADA POR NEGLIGENCIA, FALSIFICACIÓN
                  FRAUDULENTA O CUALQUIER OTRA RESPONSABILIDAD EN LA MEDIDA EN
                  QUE LA RESPONSABILIDAD LEGAL NO PUEDA EXCLUIRSE O LIMITARSE EN
                  LA LEY APLICABLE, EN NINGÚN CASO SEREMOS RESPONSABLES POR
                  DAÑOS DIRECTOS, INDIRECTOS, FORTUITOS, ESPECIALES, EJEMPLARES
                  O COLATERALES (INCLUIDOS, ENTRE OTROS, LA ADQUISICIÓN DE
                  BIENES O SERVICIOS SUSTITUTIVOS, PÉRDIDA DE DATOS O GANANCIAS,
                  CESE DEL NEGOCIO, ETC.), INDEPENDIENTEMENTE DE LA CAUSA Y DE
                  CUALQUIER OTRA RESPONSABILIDAD, YA SEA CONTRACTUAL,
                  RESPONSABILIDAD ESTRICTA O AGRAVIO (INCLUIDA LA NEGLIGENCIA Y
                  OTRAS) QUE SURJAN DE ALGUNA FORMA DEL USO DEL SOFTWARE, AÚN
                  CUANDO SEPAMOS O HAYAMOS SIDO ADVERTIDOS DE LA POSIBILIDAD DE
                  DICHO DAÑO.{" "}
                  <b>
                    LAS EXCLUSIONES Y EXENCIONES DE RESPONSABILIDAD ANTERIORES
                    SON UNA PARTE FUNDAMENTAL DE ESTE EULA.
                  </b>
                </p>
                <p>
                  <b>9.3. COMPENSACIÓN EXCLUSIVA.</b> SI CUALQUIER RAZÓN,
                  EXCLUSIÓN, DESCARGO DE RESPONSABILIDAD U OTRA DISPOSICIÓN
                  INCLUIDA EN ESTE CONTRATO SE CONSIDERA INVÁLIDA POR UN
                  TRIBUNAL DE JURISDICCIÓN COMPETENTE Y RESULTAMOS RESPONSABLES
                  POR PÉRDIDAS O DAÑOS QUE PUEDAN LIMITARSE LEGALMENTE, NUESTRA
                  RESPONSABILIDAD TOTAL HACIA USTED O CUALQUIER OTRA PARTE POR
                  CUALQUIER PÉRDIDA O DAÑO RESULTANTE DE CUALQUIER RECLAMACIÓN,
                  DEMANDA O ACCIÓN RESULTANTE DE O RELACIONADA CON ESTE EULA, NO
                  DEBERÁ EXCEDER DE LAS TARIFAS TOTALES PAGADAS POR USTED POR EL
                  SOFTWARE. NO OBSTANTE LO ANTERIOR,{" "}
                  <b>
                    SU ÚNICO Y EXCLUSIVO DERECHO Y RECURSO EN CASO DE
                    INSATISFACCIÓN CON EL SOFTWARE Y CUALQUIER SERVICIO
                    RELACIONADO, O CUALQUIER OTRA QUEJA DESPUÉS DEL PLAZO DADO
                    EN LA SECCIÓN 5.2. SERÁ SU FINALIZACIÓN Y ABANDONO DEL
                    ACCESO Y USO DEL SOFTWARE.
                  </b>
                </p>
                <h2>SECCIÓN 10.- INDEMNIZACIÓN</h2>
                <p>
                  <b>10.1. Indemnización.</b> Usted deberá indemnizarnos y
                  eximirnos de responsabilidad por todos los daños, pérdidas y
                  gastos de cualquier tipo (incluidas tarifas y costes legales
                  razonables) surgidos de cualquier reclamación hecha por un
                  tercero (incluidas, sin límites, cualquier agencia,
                  administración pública, entidad, etc.) contra Nosotros en
                  relación con cualquiera de los siguientes actos cometidos por
                  Usted: (i) violar o incumplir cualquier término de este EULA;
                  (ii) violar cualquier Ley aplicable; (iii) Usar o usar mal el
                  Software.
                </p>
                <p>
                  <b>10.2. Compensaciones.</b> Usted acepta y reconoce
                  expresamente que cualquier violación de sus obligaciones
                  conforme a este EULA puede causarnos daños y perjuicios
                  irreparables no subsanables legalmente. Por lo tanto, Usted
                  acepta que Nuestras compensaciones por el incumplimiento de
                  este EULA puedan equipararse a través de un resarcimiento por
                  mandato judicial, así como cualquier otro resarcimiento
                  disponible, ya sea por ley o en equidad.
                </p>
                <h2>SECCIÓN 11.- GENERAL</h2>
                <p>
                  <b>11.1. Política de abandono.</b> El Software está sujeto a
                  nuestra política de abandono, por lo que Nos reservamos el
                  derecho de cesar todo el servicio de asistencia para el
                  Software y / o cualquier característica, servicio, contenido o
                  Actualizaciones que tengan relación, de conformidad con dicha
                  política de abandono.
                </p>
                <p>
                  <b>11.2. Cesión de uso.</b> No se le permite ceder este EULA
                  ni ninguno de sus derechos, a menos que cuente con Nuestro
                  consentimiento previo por escrito. Por el contrario, se Nos
                  permite, a nuestra entera discreción, ceder este EULA o
                  cualquier derecho en virtud del mismo a cualquier tercero, sin
                  previo aviso.
                </p>
                <p>
                  <b>11.3. Entidades independientes.</b> Somos un ente
                  independiente, de modo que no existe relación alguna de
                  asociación, empresa conjunta o agencia de representación entre
                  Usted y Nosotros. Usted y Nosotros somos responsables de pagar
                  nuestros respectivos empleados, incluidos los impuestos y los
                  seguros relacionados con el empleo.
                </p>
                <p>
                  <b>11.4. Fuerza mayor.</b> No seremos responsables de ningún
                  incumplimiento debido a circunstancias imprevistas o causas
                  ajenas a Nuestro control razonable, incluidos, entre otros,
                  actos de fuerza mayor, guerra, disturbios, embargos, actos de
                  autoridades civiles o militares, incendios, inundaciones,
                  accidentes, huelgas, cierres patronales, o escasez de
                  transporte, instalaciones, combustible, energía, mano de obra
                  o materiales. En caso de retraso, estaremos exonerados de
                  dicho desempeño en la medida en que el retraso sea causado por
                  dichas causas.
                </p>
                <p>
                  <b>11.5. Sin renuncia implícita.</b> Cualquier tolerancia o
                  silencio por Nuestra parte, incluso de forma continua y
                  reiterada, en relación con cualquier violación o
                  incumplimiento por Su parte de cualquier disposición de este
                  EULA, no se considerará como consentimiento de tales
                  violaciones o incumplimientos y no afectará a la validez de la
                  cláusula incumplida ni se interpretará como una renuncia a
                  ninguno de los derechos que Nos corresponden de conformidad
                  con este EULA y / o la Ley aplicable.
                </p>
                <p>
                  <b>11.6. Ley aplicable y jurisdicción exclusiva.</b> Este EULA
                  se rige por la Ley de Honk Kong, sin considerar disposiciones
                  sobre conflicto de leyes. Cualquier disputa surgida o
                  relacionada con este EULA se someterá a la jurisdicción
                  exclusiva de los Tribunales de Honk Kong.
                </p>
                <p>
                  <b>11.7. Separación de cláusulas.</b> Si alguna parte de este
                  EULA se declara ilegal o inaplicable, la parte restante
                  permanecerá vigente y causará plenos efectos.
                </p>
                <p>
                  <b>11.8. Enmiendas.</b> Nosotros podemos, bajo nuestro
                  criterio, modificar este EULA en cualquier momento mediante la
                  publicación de una versión revisada del mismo en nuestro sitio
                  web <a href="/eula">www.pdfcreator.es/eula/</a>. Asimismo,
                  proporcionaremos un aviso previo con un tiempo razonable de
                  cualquier enmienda que incluya un cambio que reduzca Sus
                  derechos o aumente Sus responsabilidades. El uso continuado
                  del Software por Su parte después de los cambios posteriores
                  el EULA tras la fecha de entrada en vigor de una versión
                  revisada del mismo, constituye Su aceptación y acuerdo expreso
                  de estar sujeto al EULA vigente en cada momento.
                </p>
              </div>
            </div>
          )}
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
