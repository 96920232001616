import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/eulaPage/hero";
import Layout from "../components/shared/layout";
import { useIntl } from "gatsby-plugin-react-intl";

function Eula() {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "LicensePageTitle",
        })}
        description={intl.formatMessage({
          id: "LicensePageDescription",
        })}
      />
      <Hero />
    </Layout>
  );
}

export default Eula;
